import React, { useState } from 'react';
import { Space } from 'antd';
// import { LinkOutlined, DisconnectOutlined } from '@ant-design/icons';

const WalletConnector = () => {
    return (
        <div style={{ textAlign: 'right', padding: '10px' }}>
            <Space >
                <w3m-button />
            </Space>
        </div>
    );
}

export default WalletConnector;
